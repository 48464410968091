import React, { useEffect, useState } from "react"
import Differentiator from "../components/Differentiator"
import Footer from "../components/Footer"
import ProfessionHerov4 from "../components/ProfessionHerov4"
import "../styles/321global.css"
import "../styles/professionpage3.css"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import ReactHtmlParser from "react-html-parser"
import Session321 from "../components/Session321"
import SectionForV3 from "../components/SectionForV3"
import CoverageForV3 from "../components/CoverageForV3"

const ProfessionPage = data => {
  const [industryPageHero, setIndustryPageHero] = useState([])
  const [industryPageBody, setIndustryPageBody] = useState([])
  const [pageHero, setPageHero] = useState(null)
  const [pageBody, setPageBody] = useState(null)

  useEffect(() => {
    if (data?.data?.butterPage?.industry_page_hero) {
      setIndustryPageHero(data?.data?.butterPage?.industry_page_hero)
    }
    if (data?.data?.butterPage?.industry_page_body) {
      setIndustryPageBody(data?.data?.butterPage?.industry_page_body)
    }
  }, [])

  useEffect(() => {
    const heroData = {}
    industryPageHero.forEach(element => {
      if (element?.fields) {
        for (const key in element?.fields) {
          if (Object.hasOwnProperty.call(element?.fields, key)) {
            const data = element?.fields[key]
            if (!heroData[key]) {
              heroData[key] = data
            }
          }
        }
      }
    })
    setPageHero(heroData)
  }, [industryPageHero])

  useEffect(() => {
    const bodyData = {}
    industryPageBody.forEach(element => {
      if (element?.fields) {
        for (const key in element?.fields) {
          if (Object.hasOwnProperty.call(element?.fields, key)) {
            const data = element?.fields[key]
            if (!bodyData[key] && key !== "recommended_coverage_box") {
              bodyData[key] = data
            } else if (key === "recommended_coverage_box") {
              if (bodyData[key]?.length) {
                bodyData[key] = [...bodyData[key], ...data]
              } else {
                bodyData[key] = data
              }
            }
          }
        }
      }
    })
    setPageBody(bodyData)
  }, [industryPageBody])

  // return <>"Amir Ali"</>
  return (
    <div id="profession_page">
      <Helmet>
        <title>{pageHero?.title}</title>
        <meta name="description" content={pageHero?.Description}></meta>
        <Session321 />
      </Helmet>
      <div id="media_hero_bg">
        <ProfessionHerov4
          heroHeadline={ReactHtmlParser(pageHero?.hero_headline)}
          heroSubhead={ReactHtmlParser(
            `<p class='hero-top-sub-heading'>${pageHero?.hero_paragraph}</p>`
          )}
          bgImage={pageHero?.hero_image}
        />
      </div>
      <SectionForV3
        sectionHeading={ReactHtmlParser(pageBody?.section_heading_a)}
        sectionBody={ReactHtmlParser(pageBody?.section_paragraph_a)}
        sectionList={ReactHtmlParser(pageBody?.section_unordered_list_a)}
      />

      <SectionForV3
        sectionHeading={ReactHtmlParser(pageBody?.section_heading_b)}
        sectionBody={ReactHtmlParser(pageBody?.section_paragraph_b)}
        sectionList={ReactHtmlParser(pageBody?.section_unordered_list_b)}
      />

      <SectionForV3
        sectionHeading={ReactHtmlParser(pageBody?.section_heading_c)}
        sectionBody={ReactHtmlParser(pageBody?.section_paragraph_c)}
        sectionList={ReactHtmlParser(pageBody?.section_unordered_list_c)}
      />

      <SectionForV3
        sectionHeading={ReactHtmlParser(pageBody?.section_heading_d)}
        sectionBody={ReactHtmlParser(pageBody?.section_paragraph_d)}
        sectionList={ReactHtmlParser(pageBody?.section_unordered_list_d)}
      />
      {/* <div id="coverage_recos"> */}
      {pageBody?.recommended_coverage_box?.length > 0 ? (
        <CoverageForV3
          recommendedCoverages={pageBody?.recommended_coverage_box}
          coveragesHeader={pageBody?.recommended_coverages_header}
        />
      ) : (
        ""
      )}

      <div id="profession_paragraph_two"></div>
      <Differentiator buttonText="Request a Quote" />
      {/* <Service /> */}
      <Footer />
    </div>
  )
}
export default ProfessionPage

export const query = graphql`
  query($slug: String) {
    butterPage(
      slug: { eq: $slug }
      page_type: { eq: "product_page_01_20_2022" }
    ) {
      industry_page_body {
        fields {
          recommended_coverages_header
          section_heading_a
          section_heading_b
          section_heading_c
          section_heading_d
          section_paragraph_a
          section_paragraph_b
          section_paragraph_c
          section_paragraph_d
          section_unordered_list_a
          section_unordered_list_b
          section_unordered_list_c
          section_unordered_list_d
          recommended_coverage_box {
            recommended_coverage_box_header
            recommended_coverage_box_paragraph
            recommended_coverage_slug
          }
        }
      }
      industry_page_hero {
        fields {
          Description
          hero_headline
          hero_image
          hero_paragraph
          image
          thumbnail
          title
        }
      }
      slug
    }
  }
`
