import React from "react"
// import { Link } from "gatsby"
import CoverageForV3Card from "./CoverageForV3Card"

const CoverageForV3 = props => {
  return (
    <div id="coverage_recos">
      <div id="recommended_coverages">
        <h2>{props?.coveragesHeader}</h2>
        <div className="reco-container">
          {props?.recommendedCoverages?.map(coverage => (
            <CoverageForV3Card
              key={coverage?.recommended_coverage_box_header}
              coverageHeading={coverage?.recommended_coverage_box_header}
              coverageParagraph={coverage?.recommended_coverage_box_paragraph}
            />
          ))}
        </div>
        {/* <div className="text-block">
                <p>Selecting the right types and levels of coverage for your business helps protect what you've worked hard to build.</p>
                <p>Once we receive your quote request, we'll give you a call to review your information and suggest any additional coverage you may want to consider based on your business details.</p>
            </div> */}
      </div>
    </div>
  )
}

export default CoverageForV3
