import React from "react"

import ReactHtmlParser from "react-html-parser"

import coverageImg from "../img/4C9B8641.jpg"
import { Link } from "gatsby"

const CoverageForV3Card = props => {
  return (
    <div className="card">
      <Link to={"/"}>
        <div className="profession-icon">
          <img src={coverageImg} height="50px" />
        </div>
        <h4>{ReactHtmlParser(props?.coverageHeading)}</h4>
        <p>{ReactHtmlParser(props?.coverageParagraph)}</p>
      </Link>
    </div>
  )
}

export default CoverageForV3Card
