import React, { useEffect } from "react"
import logo from "../img/insurance321_logo_white.svg"
import RocketPhone from "../img/rocket-to-phone-white.gif"
import "../styles/nav.css"
import "../styles/media-home.css"
import "../styles/nomination.css"
import "../styles/multistep-quote.css"
import "../styles/hero-v4.css"
import Session321 from "./Session321"
import { Link } from "gatsby"
import BoxExpanderRight from "./BoxExpanderRight.js"
import MultiStepForm from "./multistep-form/MultiStepForm"
import QuoteCarousel from "./QuoteCarousel"

export default function QuoteAppHero(props) {
  return (
    <div className="multistep-hero" id="media_hero">
      <Session321 />
      <div className="hero-top">
        <div id="logo">
        <Link to="/"><img src={logo} alt="Insurance321 Logo" /></Link>
        </div>
        
          {/* <a id="number" href="tel:+1-234-230-6321"> */}
            <div className="call-conversion">
              <div id="call_icon">
                  <h4>234-230-6321</h4>
              </div>
            </div>
          {/* </a> */}
        
      </div>
      <div className="hero-bottom hero-bottom-v4">
        <div
          id="hero_left"
          style={{ backgroundImage: `url('${props?.bgImage}')` }}
        >
          <div className="medium-blue-overlay">
            <div className="hero-text">
              <div className="header">
                <h1>{props.heroHeadline}</h1>
              </div>
              {/* <QuoteComparison /> */}
              {/* <div id="quote_image"><img src={QuoteAnimation} alt="quote animation" /></div> */}
              {/* <QuoteCarousel /> */}
              <div id="hero_subhead">{props.heroSubhead}</div>
            </div>
          </div>
        </div>
        <div id="hero_right">
          <BoxExpanderRight />
          <div id="multistep_form">
            <MultiStepForm />
          </div>
        </div>
      </div>
    </div>
  )
}
