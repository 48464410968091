import React from "react"

import "../styles/SectionForV3.css"

export default function SectionForV3(props) {
  if (props?.sectionHeading?.length && props?.sectionBody?.length) {
    return (
      <div>
        <div className="sectionHeading">
          <h2>{props?.sectionHeading}</h2>
          <p>{props?.sectionBody}</p>
          {props?.sectionList}
        </div>
      </div>
    )
  }
  return <></>
}
